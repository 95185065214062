/* eslint-disable */
import React from 'react';
import Layout from '../components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Experiencetab from '../components/Experiencetab';
import Gettouch from '../components/Gettouch';
import Headinfo from '../components/Headinfo';
import Meeting from '../components/Meeting';
import ConnectedTop from '../components/connectedinsightstop';
import Louisbester from '../assets/images/Louis-bester.jpg';
import Stephangrobler from '../assets/images/Stephan-grobler.jpg';
import { Helmet } from 'react-helmet';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const insightsconnected = (props) => {
  const insightsconnectedImages = useStaticQuery(graphql`
    query insightsconnectedImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='experienceurbianpage teal'>
      <Layout>
        <Helmet>
          <title>Urbian | Connected insights</title>
        </Helmet>
        <ConnectedTop></ConnectedTop>
        <Container className='spb-5 border-bottom'>
          <Row>
            <Col lg='6'>
              <div className='sp-5'>
                <h3>What is it?</h3>
                <p>
                  The hunger for big data is insatiable but if you can’t connect
                  multiple sources, you’ll stay blind. Collecting data by itself
                  delivers no value, it needs to be actioned. Connecting the
                  right data together is the first step in this process.
                </p>
                <p>
                  There's gold in data when its collection and consumption is
                  automated, predictive and communicated. We’ll help you create
                  a data strategy, digging into your current data landscape and
                  recommending what data to connect, how, and why. From
                  connecting CRM with product analytics data to instore - online
                  sales data, we can help you maximise the value of the data you
                  collect.
                </p>
              </div>
              <div className='sp-5'>
                <h3>Who is it for?</h3>
                <p>Marketers, Head of Sales and Product Managers.</p>
              </div>
              <div className='sp-5'>
                <h3>What will you learn?</h3>
                <ul>
                  <li>Understand the value of connected systems and data</li>
                  <li>Predict and improve performance based on data</li>
                  <li>Plan a fully connected data infrastructure</li>
                </ul>
              </div>
              <h3>Who will run it?</h3>
              <p>A Product Owner or Delivery Lead.</p>
              <div className='team-grid'>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={
                        insightsconnectedImages.louis.childImageSharp.sizes
                      }
                      alt='Louis Bester'
                    />
                  </div>
                  <h6>Louis Bester</h6>
                  <span>Product Owner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={insightsconnectedImages.jay.childImageSharp.sizes}
                      alt='Jay Thomson'
                    />
                  </div>
                  <h6>Jay Thomson</h6>
                  <span>Managing Partner</span>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <Meeting location={props.location.pathname}></Meeting>
            </Col>
          </Row>
        </Container>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  );
};
export default insightsconnected;
